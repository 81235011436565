import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';
import axios from 'axios';

let api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE || window.location.origin + '/api/',
    timeout: 20000,
});

Vue.use(Router);

/*
Note that is the path is
/example
it will lead to
www.domain.com/example
If the path is
example
it will extend the current url
www.domain.com/current-page/example
 */

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../pages/public/Login.vue'),
        meta: {
            title: 'Login',
            public: true,
            publicOnly: true,
        },
    },
    {
        path: '/forgotpassword',
        component: () => import('../pages/public/ForgotPassword.vue'),
        meta: {
            title: 'Forgot password',
            public: true,
            publicOnly: true,
        },
    },
    {
        path: '/resetpassword',
        component: () => import('../pages/public/ResetPassword.vue'),
        meta: {
            title: 'Forgot password',
            public: true,
            publicOnly: true,
        },
    },
    {
        path: '/onboarding',
        component: () => import('../pages/public/OnboardingUsers.vue'),
        meta: {
            title: 'Onboarding',
            public: true,
            publicOnly: true,
        },
    },
    {
        name: 'Home',
        path: '/',
        meta: { noRedirect: true },
        beforeEnter: (to, from, next) => {
            switch (store.state.user.user_type) {
                case 'admin':
                    next({ name: 'AdminHome' });
                    break;
                case 'partner':
                    next({ name: 'PartnerHome' });
                    break;
            }
        },
    },
    {
        name: 'MyAccount',
        path: '/account',
        components: {
            default: () =>
              import(
                /* webpackChunkName: "common" */ '../pages/account/MyAccount'
                ),
            toolbar: () =>
              import(
                /* webpackChunkName: "common" */ '../components/core/toolbars/DefaultToolbar'
                ),
        },
        meta: { title: 'My Account' },
    },
    {
        name: 'PartnerParent',
        path: '/partner',
        components: {
            default: () =>
              import(
                /* webpackChunkName: "partner" */ '../pages/partners/PartnerParent'
                ),
            toolbar: () =>
              import(
                /* webpackChunkName: "common" */ '../components/core/toolbars/PartnerToolbar'
                ),
        },
        meta: { userTypes: ['partner'] },
        children: [
            {
                name: 'PartnerHome',
                path: 'home',
                component: () =>
                  import(
                    /* webpackChunkName: "partner" */ '../pages/partners/PartnerHome'
                    ),
                meta: {
                    title: 'Partner Home',
                    noRedirect: true,
                    userTypes: ['partner']
                },
            },
            {
                name: 'PartnerContacts',
                path: 'contacts',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/partners/PartnerContacts'
                    ),
                meta: { title: 'Contacts' },
            },
            {
                name: 'PartnerCustomers',
                path: 'customers',
                component: () =>
                  import(
                    /* webpackChunkName: "partner" */ '../pages/partners/PartnerCustomers'
                    ),
                meta: { title: 'Customers'},
            },
            {
                name: 'PartnerCommissions',
                path: 'commissions',
                component: () =>
                  import(
                    /* webpackChunkName: "partner" */ '../pages/partners/PartnerCommissions'
                    ),
                meta: { title: 'Commissions'},
            },
            {
                name: 'PartnerHistory',
                path: 'history',
                component: () =>
                  import(
                    /* webpackChunkName: "partner" */ '../pages/partners/PartnerHistory'
                    ),
                meta: { title: 'History'},
            },
            {
                name: 'PartnerNBNSearch',
                path: 'nbn-search',
                component: () =>
                  import(
                    /* webpackChunkName: "partner" */ '../pages/partners/PartnerNBNSearch'
                    ),
                meta: { title: 'NBN Site Qualification' },
            },
            {
                name: 'PartnerIPNDDetails',
                path: 'emergency-service-addresses/:vendorNumber/:ipndId(\\d+)',
                component: () =>
                  import(
                    /* webpackChunkName: "partner" */ '../pages/partners/PartnerIPNDDetails'
                    ),
                meta: { title: 'Emergency Service Address' },
            },
            {
                name: 'PartnerOperationalStatus',
                path: 'operational-status',
                component: () =>
                  import(
                    /* webpackChunkName: "partner" */ '../pages/partners/PartnerOperationalStatus.vue'
                    ),
                meta: { title: 'Operational Status' },
            },
            {
                name: 'PartnerIpndOnboarding',
                path: 'ipnd-onboarding',
                component: () =>
                  import(
                    /* webpackChunkName: "partner" */ '../pages/partners/PartnerIpndOnboarding.vue'
                    ),
                meta: { title: 'Emergency Service Addresses (IPND) Onboarding' },
            },
            {
                name: 'PartnerIpndManagement',
                path: 'ipnd-management',
                component: () =>
                  import(
                    /* webpackChunkName: "partner" */ '../pages/partners/PartnerIpndOnboarding.vue'
                    ),
                meta: { title: 'Emergency Service Addresses Management' },
            },
        ],
    },
    {
        name: 'AdminPartnerParent',
        path: '/admin/partner/:partnerId(\\d+)',
        components: {
            default: () =>
              import(
                /* webpackChunkName: "partner" */ '../pages/partners/PartnerParent'
                ),
            toolbar: () =>
              import(
                /* webpackChunkName: "common" */ '../components/core/toolbars/PartnerToolbar'
                ),
            menu: () =>
              import(
                /* webpackChunkName: "partner" */ '../components/core/menu/PartnerMenu'
                ),
        },
        meta: {
            redirect: 'AdminPartnerSummary',
            userTypes: ['admin']
        },
        children: [
            {
                name: 'AdminPartnerSummary',
                path: 'summary',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/partners/PartnerSummary'
                    ),
                meta: { title: 'Partner Details' },
            },
            {
                name: 'AdminPartnerContacts',
                path: 'contacts',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/partners/PartnerContacts'
                    ),
                meta: { title: 'Contacts' },
            },
            {
                name: 'AdminPartnerCustomers',
                path: 'customers',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/partners/PartnerCustomers'
                    ),
                meta: { title: 'Customers' },
            },
            {
                name: 'AdminPartnerCommissions',
                path: 'commissions',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/partners/PartnerCommissions'
                    ),
                meta: { title: 'Commissions' },
            },
            {
                name: 'AdminPartnerHistory',
                path: 'history',
                component: () =>
                  import(
                    /* webpackChunkName: "partner" */ '../pages/partners/PartnerHistory'
                    ),
                meta: { title: 'History' },
            },
            {
                name: 'AdminPartnerNBNSearch',
                path: 'nbn-search',
                component: () =>
                  import(
                    /* webpackChunkName: "partner" */ '../pages/partners/PartnerNBNSearch'
                    ),
                meta: { title: 'NBN Site Qualification' },
            },
            {
                name: 'AdminPartnerOperationalStatus',
                path: 'operation-status',
                component: () =>
                  import(
                    /* webpackChunkName: "partner" */ '../pages/partners/PartnerOperationalStatus.vue'
                    ),
                meta: { title: 'Operational Status' },
            },
            {
                name: 'AdminPartnerIpndOnboarding',
                path: 'ipnd-onboarding',
                component: () =>
                  import(
                    /* webpackChunkName: "partner" */ '../pages/partners/PartnerIpndOnboarding.vue'
                    ),
                meta: { title: 'Emergency Service Addresses (IPND) Onboarding' },
            },
            ,
            {
                name: 'AdminPartnerIpndManagement',
                path: 'ipnd-management',
                component: () =>
                  import(
                    /* webpackChunkName: "partner" */ '../pages/partners/PartnerIpndOnboarding.vue'
                    ),
                meta: { title: 'Emergency Service Addresses Management' },
            },
        ],
    },

    {
        name: 'Customer',
        path: '/customer/:customerId(\\d+)',
        components: {
            default: () =>
              import(
                /* webpackChunkName: "customer" */ '../pages/customers/CustomerParent'
                ),
            menu: () =>
              import(
                /* webpackChunkName: "customer" */ '../components/core/menu/CustomerMenu'
                ),
            toolbar: () =>
              import(
                /* webpackChunkName: "customer" */ '../components/core/toolbars/CustomerToolbar'
                ),
        },
        meta: {
            redirect: 'CustomerSummary'
        },
        children: [
            {
                name: 'CustomerSummary',
                path: 'summary',
                meta: { title: 'Summary' },
                component: () =>
                  import(
                    /* webpackChunkName: "customer" */ '../pages/customers/CustomerSummary'
                    ),
            },
            {
                name: 'CustomerTrunks',
                path: 'trunks',
                meta: { title: 'SIP Trunks'},
                component: () =>
                  import(
                    /* webpackChunkName: "customer" */ '../pages/customers/CustomerTrunkList'
                    ),
            },
            {
                name: 'CustomerExtensions',
                path: 'extensions',
                meta: { title: 'Extensions'},
                component: () =>
                  import(
                    /* webpackChunkName: "customer" */ '../pages/customers/CustomerExtensionList'
                    ),
            },
            {
                name: 'CustomerRingGroups',
                path: 'ring-groups',
                meta: { title: 'Ring Groups'},
                component: () =>
                  import(
                    /* webpackChunkName: "customer" */ '../pages/customers/CustomerRingGroupList'
                    ),
            },
            {
                name: 'CustomerCallQueues',
                path: 'call-queues',
                meta: { title: 'Call Queues'},
                component: () =>
                  import(
                    /* webpackChunkName: "customer" */ '../pages/customers/CustomerCallQueueList'
                    ),
            },
            {
                name: 'CustomerFaxToMails',
                path: 'fax-to-mails',
                meta: { title: 'Fax To Mail'},
                /*beforeEnter: (to, from) => {
                    if(store.state.data.customer.customer_class_tag != 'hosted-extension'){
                        return false;
                    }
                    return true;
                },*/
                component: () =>
                  import(
                    /* webpackChunkName: "customer" */ '../pages/customers/CustomerFaxToMailList'
                    ),
            },
            {
                name: 'CustomerAutoAttendantAccounts',
                path: 'auto-attendant-accounts',
                meta: { title: 'Auto Attendant Accounts'},
                /*beforeEnter: (to, from) => {
                    if(store.state.data.customer.customer_class_tag != 'hosted-extension'){
                        return false;
                    }
                    return true;
                },*/
                component: () =>
                  import(
                    /* webpackChunkName: "customer" */ '../pages/customers/CustomerAutoAttendantList'
                    ),
            },
            {
                name: 'CustomerHistory',
                path: 'history',
                meta: { title: 'History' },
                component: () =>
                  import(
                    /* webpackChunkName: "customer" */ '../pages/customers/CustomerHistory'
                    ),
            },
            {
                name: 'CustomerContacts',
                path: 'contacts',
                meta: { title: 'Contacts' },
                component: () =>
                  import(
                    /* webpackChunkName: "customer" */ '../pages/customers/CustomerContacts'
                    ),
            },
            {
                name: 'CustomerDIDs',
                path: 'dids',
                meta: { title: 'DIDs' },
                component: () =>
                  import(
                    /* webpackChunkName: "customer" */ '../pages/customers/CustomerDIDs'
                    ),
            },

            {
                name: 'CustomerCallLogs',
                path: 'call-logs',
                meta: { title: 'Call Logs' },
                component: () =>
                  import(
                    /* webpackChunkName: "customer" */ '../pages/customers/CustomerCallLogs'
                    ),
            },
            {
                name: 'CustomerPendingVoipDevices',
                path: 'pending-devices',
                meta: { title: 'Pending Devices'},
                component: () =>
                  import(
                    /* webpackChunkName: "customer" */ '../pages/customers/CustomerVoipDevicePendingList'
                    ),
            },
            {
                name: 'CustomerExtensionVoipDevices',
                path: 'extension-devices',
                meta: { title: 'Extension Devices'},
                component: () =>
                  import(
                    /* webpackChunkName: "customer" */ '../pages/customers/CustomerVoipDeviceList'
                    ),
            },
            {
                name: 'CustomerNBNSearch',
                path: 'nbn-search',
                component: () =>
                  import(
                    /* webpackChunkName: "partner" */ '../pages/customers/CustomerNBNSearch'
                    ),
                meta: { title: 'NBN Site Qualification' },
            },
            {
                name: 'CustomerOperationalStatus',
                path: 'operation-status',
                component: () =>
                  import(
                    /* webpackChunkName: "partner" */ '../pages/customers/CustomerOperationalStatus.vue'
                    ),
                meta: { title: 'Operational Status' },
            },
        ]
    },

    {
        path: '/customer/:customerId(\\d+)/trunks',
        components: {
            default: () =>
              import(
                /* webpackChunkName: "customer" */ '../pages/customers/CustomerParent'
                ),
            menu: () =>
              import(
                /* webpackChunkName: "customer" */ '../components/core/menu/CustomerMenu'
                ),
            toolbar: () =>
              import(
                /* webpackChunkName: "customer" */ '../components/core/toolbars/CustomerToolbar'
                ),
        },
        props: {
            toolbar: { extension: 'trunk' },
        },
        meta: {
        },
        children: [
            {
                name: 'Trunk',
                path: ':trunkId(\\d+)',
                components: {
                    default: () =>
                      import(
                        /* webpackChunkName: "customer" */ '../pages/trunks/TrunkParent'
                        ),
                },
                meta: {
                    // redirect: 'TrunkSummary',
                    back: { name: 'CustomerTrunks' },
                },
                children: [
                    {
                        name: 'TrunkSummary',
                        path: 'summary',
                        /*component: () =>
                            import(
                                /* webpackChunkName: "customer" *//* '../pages/trunks/TrunkSummary'
                                    ),*/
                        components: {
                            default: () =>
                              import(
                                /* webpackChunkName: "customer" */ '../pages/trunks/TrunkSummary'
                                ),
                        },
                        meta: { title: 'Summary' }
                    },
                    {
                        name: 'TrunkBilling',
                        path: 'billing',
                        component: () =>
                          import(
                            /* webpackChunkName: "customer" */ '../pages/trunks/TrunkBilling'
                            ),
                        meta: { title: 'Billing' },
                    },
                    {
                        name: 'TrunkDIDs',
                        path: 'dids',
                        component: () =>
                          import(
                            /* webpackChunkName: "customer" */ '../pages/trunks/TrunkDIDs'
                            ),
                        meta: { title: 'DIDs' }
                    },
                    {
                        name: 'TrunkOutboundCallSettings',
                        path: 'outbound',
                        component: () =>
                          import(
                            /* webpackChunkName: "customer" */ '../pages/trunks/TrunkOutboundCallSettings'
                            ),
                        meta: { title: 'Outbound settings' }
                    },
                    {
                        name: 'TrunkInboundCallSettings',
                        path: 'inbound',
                        component: () =>
                          import(
                            /* webpackChunkName: "customer" */ '../pages/trunks/TrunkInboundCallSettings'
                            ),
                        meta: { title: 'Inbound settings' }
                    },
                    {
                        name: 'TrunkCallLogs',
                        path: 'call-logs',
                        component: () =>
                          import(
                            /* webpackChunkName: "customer" */ '../pages/trunks/TrunkCallLogs'
                            ),
                        meta: { title: 'Call Logs' }
                    },
                    {
                        name: 'ExtensionVoipDeviceList',
                        path: 'devices',
                        component: () =>
                          import(
                            /* webpackChunkName: "customer" */ '../pages/extensions/ExtensionVoipDeviceList'
                            ),
                        meta: {title: 'Device List'},
                    },
                    {
                        name: 'ExtensionRingGroupList',
                        path: 'ring-groups',
                        component: () =>
                          import(
                            /* webpackChunkName: "customer" */ '../pages/extensions/ExtensionRingGroupList'
                            ),
                        meta: {title: 'Ring Group List'},
                    },
                    {
                        name: 'RingGroupExtensionList',
                        path: 'extensions',
                        component: () =>
                          import(
                            /* webpackChunkName: "customer" */ '../pages/ringgroups/RingGroupExtensionList'
                            ),
                        meta: {title: 'Ring Group Extension List'},
                    },
                    {
                        name: 'AutoAttendantAccountAutoAttendantList',
                        path: 'auto-attendants',
                        component: () =>
                          import(
                            /* webpackChunkName: "customer" */ '../pages/autoattendant/AutoAttendantAccountAutoAttendantList'
                            ),
                        meta: {title: 'Auto Attendant List'},
                    },
                    {
                        name: 'TrunkIPNDList',
                        path: 'emergency-services-addresses',
                        component: () =>
                          import(
                            /* webpackChunkName: "customer" */ '../pages/trunks/TrunkIPNDList.vue'
                            ),
                        meta: {title: 'Emergency Services Addresses'},
                    },
                    {
                        name: 'TrunkIPNDDetails',
                        path: 'emergency-services-addresses/:vendorNumber(\\d+)/:ipndId(\\d+)',
                        component: () =>
                          import(
                            /* webpackChunkName: "admin" */ '../pages/trunks/TrunkIPNDDetails.vue'
                            ),
                        meta: { title: 'Emergency Service Address' },
                    },
                ],
            },
        ],
    },

    /*
        Note that the reason we need to reset the CustomerParent, TrunkParent and toolbar pages is
        due the to the way this application has been setup and limitations of Vue.
        Because the toolbar is added at the highest level it cannot be manipulated by child routes.
        If this was possible it would be much neater as we could just define the toolbar from a child.
        However the only way I've found to set the toolbar with the current layout is to reset the
        CustomerParent, the TrunkParent and the toolbar.

        Else We would need to refactor how all this works and at this moment I do not have time to do this.
        Really a more suitable and flexible layout infrastructure need to be deployed which would results is significant
        refactoring or many elements and parts of the application.

     */
    {
        path: '/customer/:customerId(\\d+)/trunks',
        components: {
            default: () =>
              import(
                /* webpackChunkName: "customer" */ '../pages/customers/CustomerParent'
                ),
            menu: () =>
              import(
                /* webpackChunkName: "customer" */ '../components/core/menu/CustomerMenu'
                ),
            toolbar: () =>
              import(
                /* webpackChunkName: "customer" */ '../components/core/toolbars/CustomerToolbar'
                ),
        },
        props: {
            toolbar: { extension: 'device' },
        },
        meta: {
        },
        children: [
            {
                path: ':trunkId(\\d+)',
                components: {
                    default: () =>
                      import(
                        /* webpackChunkName: "customer" */ '../pages/trunks/TrunkParent'
                        ),
                },
                meta: {
                    // redirect: 'TrunkSummary',
                    back: { name: 'CustomerTrunks' },
                },
                children: [
                    {
                        name: 'VoipDevice',
                        path: 'devices/:voipDeviceId(\\d+)',
                        components: {
                            default: () =>
                              import(
                                /* webpackChunkName: "customer" */ '../pages/VoipDevices/VoipDeviceSummary'
                                ),
                        },
                        meta: {
                            title: 'Summary',
                            back: {name: 'ExtensionVoipDeviceList'},
                        }
                    }
                ],
            },
        ],
    },

    {
        path: '/customer/:customerId(\\d+)/call-queues',
        components: {
            default: () =>
              import(
                /* webpackChunkName: "customer" */ '../pages/customers/CustomerParent'
                ),
            menu: () =>
              import(
                /* webpackChunkName: "customer" */ '../components/core/menu/CustomerMenu'
                ),
            toolbar: () =>
              import(
                /* webpackChunkName: "customer" */ '../components/core/toolbars/CustomerToolbar'
                ),
        },
        props: {
            toolbar: { extension: 'call-queue' },
        },
        meta: {
        },
        children: [
            {
                //  name: 'CallQueue',
                path: ':callQueueId(\\d+)',
                components: {
                    default: () =>
                      import(
                        /* webpackChunkName: "customer" */ '../pages/callqueues/CallQueueParent'
                        ),
                },
                meta: {
                    //redirect: 'CallQueueSummary',
                    back: { name: 'CustomerCallQueues' },
                },
                children: [
                    {
                        name: 'CallQueueSummary',
                        path: '',
                        /*component: () =>
                            import(
                                /* webpackChunkName: "customer" *//* '../pages/trunks/TrunkSummary'
                                    ),*/
                        components: {
                            default: () =>
                              import(
                                /* webpackChunkName: "customer" */ '../pages/callqueues/CallQueueSummary'
                                ),
                        },
                        meta: { title: 'Summary' }
                    },
                ],
            },
        ],
    },


    {
        path: '/customer/:customerId(\\d+)/auto-attendant-accounts/:trunkId(\\d+)/auto-attendant',
        components: {
            default: () =>
              import(
                /* webpackChunkName: "customer" */ '../pages/customers/CustomerParent'
                ),
            menu: () =>
              import(
                /* webpackChunkName: "customer" */ '../components/core/menu/CustomerMenu'
                ),
            toolbar: () =>
              import(
                /* webpackChunkName: "customer" */ '../components/core/toolbars/CustomerToolbar'
                ),
        },
        props: {
            toolbar: { extension: 'auto-attendant' },
        },
        meta: {
        },
        children: [
            {
                //name: 'AutoAttendantAccountAutoAttendant',
                path: ':menuId(\\d+)',
                components: {
                    default: () =>
                      import(
                        /* webpackChunkName: "customer" */ '../pages/trunks/TrunkParent'
                        ),
                },
                meta: {
                    //redirect: 'CallQueueSummary',
                    back: { name: 'AutoAttendantAccountAutoAttendantList' },
                },
                children: [
                    {
                        name: 'AutoAttendantAccountAutoAttendantSummary',
                        path: '',
                        /*component: () =>
                            import(
                                /* webpackChunkName: "customer" *//* '../pages/trunks/TrunkSummary'
                                    ),*/
                        components: {
                            default: () =>
                              import(
                                /* webpackChunkName: "customer" */ '../pages/autoattendant/AutoAttendantAccountAutoAttendantSummary'
                                ),
                        },
                        meta: {
                            title: 'Summary',
                            back: { name: 'AutoAttendantAccountAutoAttendantList' },
                        },
                    },
                ],
            },
        ],
    },

    {
        path: '/customer/:customerId(\\d+)/billing',
        components: {
            default: () =>
              import(
                /* webpackChunkName: "customer" */ '../pages/customers/CustomerParent'
                ),
            menu: () =>
              import(
                /* webpackChunkName: "customer" */ '../components/core/menu/CustomerMenu'
                ),
            toolbar: () =>
              import(
                /* webpackChunkName: "customer" */ '../components/core/toolbars/CustomerToolbar'
                ),
        },
        props: {
            toolbar: {extension: 'billing'},
        },
        meta: {},
        children: [
            {
                name: 'CustomerBilling',
                path: 'billing',
                components: {
                    default: () =>
                      import(
                        /* webpackChunkName: "customer" */ '../pages/customers/CustomerBilling'
                        ),
                },
                meta: {
                    linkTo: {name: 'CustomerBillingSummary'},
                    title: 'Billing',
                },
                children: [
                    {
                        name: 'CustomerBillingSummary',
                        meta: {title: 'Billing Summary'},
                        path: 'summary',
                        component: () =>
                          import(
                            /* webpackChunkName: "customer" */ '../pages/customers/CustomerBillingSummary'
                            ),
                    },
                    {
                        name: 'CustomerBillingInvoices',
                        path: 'invoices',
                        meta: {title: 'Invoices'},
                        component: () =>
                          import(
                            /* webpackChunkName: "customer" */ '../pages/customers/CustomerInvoices'
                            ),
                    },
                    {
                        name: 'CustomerBillingTransactions',
                        path: 'transactions',
                        meta: {title: 'Transactions'},
                        component: () =>
                          import(
                            /* webpackChunkName: "customer" */ '../pages/customers/CustomerTransactions'
                            ),
                    },

                ]
            }
        ]
    },
    {
        name: 'AdminParent',
        path: '/admin',
        components: {
            default: () =>
              import(
                /* webpackChunkName: "admin" */ '../pages/GenericParent'
                ),
            toolbar: () =>
              import(
                /* webpackChunkName: "admin" */ '../components/core/toolbars/AdminToolbar'
                ),
        },
        meta: { userTypes: ['admin'] },
        children: [
            {
                name: 'AdminHome',
                path: 'home',
                components: {
                    default: () =>
                      import(
                        /* webpackChunkName: "admin" */ '../pages/admin/AdminHome.vue'
                        ),
                    // toolbar: () =>
                    //     import(
                    //         /* webpackChunkName: "common" */ '../components/core/toolbars/DefaultToolbar'
                    //     ),
                },
                props: { noLoginRedirect: true },
                meta: {
                    title: 'Home',
                    noRedirect: true,
                },
            },
            {
                name: 'PartnerList',
                path: 'partners',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/partners/PartnerList'
                    ),
                meta: { title: 'Partner List' },
            },
            {
                name: 'CustomerList',
                path: 'customers',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/customers/CustomerList'
                    ),
                meta: { title: 'Customers' },
            },
            {
                name: 'TrunkList',
                path: 'trunks',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/trunks/TrunkList'
                    ),
                meta: { title: 'Trunk List' },
            },
            {
                name: 'ExtensionList',
                path: 'extensions',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/extensions/ExtensionList'
                    ),
                meta: { title: 'Extension List' },
            },
            {
                name: 'RingGroupList',
                path: 'ring-groups',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/ringgroups/RingGroupList'
                    ),
                meta: { title: 'Ring Group List' },
            },
            {
                name: 'AutoAttendantList',
                path: 'auto-attendants',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/autoattendant/AutoAttendantList'
                    ),
                meta: { title: 'Auto Attendant List' },
            },
            {
                name: 'CallQueueList',
                path: 'call-queue',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/callqueues/CallQueueList'
                    ),
                meta: { title: 'Call Queue List' },
            },
            {
                name: 'FaxToMailList',
                path: 'fax-to-mail',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/faxtomail/FaxToMailList'
                    ),
                meta: { title: 'Fax to Mail List' },
            },
            {
                name: 'VoipDeviceList',
                path: 'extension-devices',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/VoipDevices/VoipDeviceList'
                    ),
                meta: { title: 'Extension Device List' },
            },
            {
                name: 'SignupDetail',
                path: 'signups/:id',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/signups/SignupDetail'
                    ),
                meta: { title: 'New Partner Request Details' },
            },
            {
                name: 'AdminUserList',
                path: 'users',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/users/UserList'
                    ),
                meta: { title: 'Users' },
            },
            {
                name: 'AdminAdminUserList',
                path: 'admin-users',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/users/AdminUserList'
                    ),
                meta: { title: 'Admin Users' },
            },
            // {
            //     name: 'AdminUserParent',
            //     path: 'user/:userId(\\d+)',
            //     component: () =>
            //         import(
            //             /* webpackChunkName: "admin" */ '../pages/GenericParent'
            //         ),
            //     props: {
            //         toolbar: { extension: 'user' }, //used to toggle the menu
            //     },
            //     children: [
            //         {
            //             name: 'AdminUserDetails',
            //             path: 'summary',
            //             props: {
            //                 toolbar: { extension: 'user' }, //used to toggle the menu
            //             },
            //             component: () =>
            //                 import(
            //                     /* webpackChunkName: "admin" */ '../pages/admin/users/UserDetails'
            //                     ),
            //             meta: { title: 'User Summary' },
            //         },
            //         {
            //             name: 'AdminUserSessions',
            //             path: 'sessions',
            //             component: () =>
            //                 import(
            //                     /* webpackChunkName: "admin" */ '../pages/admin/users/UserSessions'
            //                     ),
            //             meta: { title: 'User Sessions' },
            //         },
            //         {
            //             name: 'AdminUserHistory',
            //             path: 'history',
            //             component: () =>
            //                 import(
            //                     /* webpackChunkName: "admin" */ '../pages/admin/users/UserHistory'
            //                     ),
            //             meta: { title: 'User History' },
            //         },
            //
            //     ]
            // },
            {
                name: 'CronList',
                path: 'system/cron',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/cron/CronList'
                    ),
                meta: { title: 'Cron Tasks' },
            },
            {
                name: 'CronDetail',
                path: 'system/cron/:id',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/cron/CronDetail'
                    ),
                meta: { title: 'Cron Details' },
            },
            {
                name: 'SMSLog',
                path: 'syslog/sms',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/syslogs/SMSLogs'
                    ),
                meta: { title: 'SMS Log' },
            },
            {
                name: 'PortaAPILog',
                path: 'syslog/porta',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/syslogs/PortaLogs'
                    ),
                meta: { title: 'PortaAPI Log' },
            },
            {
                name: 'DIDInventory',
                path: 'dids',
                component: () =>
                  import(
                    /* webpackChunkName: "user" */ '../pages/did/DIDInventory'
                    ),
                meta: { title: 'DID Inventory' },
            },
            {
                name: 'DIDDetail',
                path: 'dids/:did',
                component: () =>
                  import(
                    /* webpackChunkName: "user" */ '../pages/did/DIDDetail'
                    ),
                meta: { title: 'DID Details' },
            },
            {
                name: 'TransferList',
                path: 'transfers',
                component: () => import('../pages/transfers/TransferList'),
                meta: { title: 'Transfers (Number Porting and Migrations)' },
            },
            {
                name: 'SignupList',
                path: 'signups',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/signups/SignupList'
                    ),
                meta: { title: 'New Partner Requests' },
            },
            {
                name: 'IPNDDetails',
                path: 'emergency-service-addresses/:vendorNumber/:ipndId(\\d+)',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/ipnd/IPNDDetailsPage.vue'
                    ),
                meta: { title: 'Emergency Service Address' },
            },
            {
                name: 'AdminOperationStatus',
                path: 'operation-status',
                component: () =>
                  import(
                    /* webpackChunkName: "partner" */ '../pages/admin/application/AdminOperationalStatus.vue'
                    ),
                meta: { title: 'Operational Status' },
            },
            {
                name: 'XeroFeatures',
                path: 'xero',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/xero/XeroInvoiceHome'
                    ),
                meta: { title: 'Xero Tronic Features' },
            },
            {
                name: 'XeroFeaturesPreview',
                path: 'xero-invoice-preview',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/xero/XeroInvoicePreview'
                    ),
                meta: { title: 'Xero Tronic Features Preview' },
            },
            {
                name: 'UiKit',
                path: 'ui-kit',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/application/AdminUiKit.vue'
                    ),
                meta: { title: 'UI Kit' },
            },
        ],
    },
    {
        //name: 'PartnerCommissionReports',
        path: '/admin/partner-commission-reports',
        components: {
            default: () =>
              import(
                /* webpackChunkName: "admin" */ '../pages/GenericParent'
                ),
            toolbar: () =>
              import(
                /* webpackChunkName: "common" */ '../components/core/toolbars/DefaultToolbar'
                ),
            menu: () =>
              import(
                /* webpackChunkName: "admin" */ '../components/core/menu/CommissionMenu'
                ),
        },
        meta: { userTypes: ['admin'] },
        children: [
            {
                name: 'PartnerCommissionReports',
                path: '',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/partner-commission-reports/PartnerCommissionReportList'
                    ),
                meta: { title: 'Partner Commission Reports' },
            },
            {
                name: 'PartnerCommissionReportView',
                path: '/admin/partner-commission-reports/:id',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/partner-commission-reports/PartnerCommissionReportView'
                    ),
                meta: { title: 'View Partner Commission Report' },
            },
            {
                name: 'PartnerCommissionReportExemptions',
                path: '/admin/partner-commission-report-exemptions',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/partner-commission-reports/PartnerCommissionReportExemptionView'
                    ),
                meta: { title: 'Partner Commission Report Exemption' },
            },
        ],
    },

    {
        //name: 'PartnerCommissionReports',
        path: '/admin/reports',
        components: {
            default: () =>
              import(
                /* webpackChunkName: "admin" */ '../pages/GenericParent'
                ),
            toolbar: () =>
              import(
                /* webpackChunkName: "common" */ '../components/core/toolbars/DefaultToolbar'
                ),
            // menu: () =>
            //   import(
            //     /* webpackChunkName: "admin" */ '../components/core/menu/CommissionMenu'
            //     ),
        },
        meta: { userTypes: ['admin'] },
        children: [
            {
                name: 'AdminReportList',
                path: '/admin/reports',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/reports/ReportList'
                    ),
                meta: { title: 'Report List' },
            },
            {
                name: 'ReportCustomerChurn',
                path: '/admin/reports/customer-churn-report',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/reports/CustomerChurnReport'
                    ),
                meta: { title: 'Customer Churn Report' },
            },
            {
                name: 'ReportNewCustomerGrowth',
                path: '/admin/reports/new-customer-growth-report',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/reports/NewCustomerGrowthReport'
                    ),
                meta: { title: 'New Customer Growth Report' },
            },
            {
                name: 'ReportNewNbnCustomerGrowth',
                path: '/admin/reports/new-nbn-customer-growth-report',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/reports/NewNbnCustomerGrowthReport'
                    ),
                meta: { title: 'New NBN Customer Growth Report' },
            },
            {
                name: 'ReportFastestPartnerRevenueGrowth',
                path: '/admin/reports/fastest-partner-revenue-growth-report',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/reports/FastestPartnerRevenueGrowthReport.vue'
                    ),
                meta: { title: 'Fastest Partner Revenue Growth Report' },
            },
            {
                name: 'ReportFastestCustomerRevenueGrowth',
                path: '/admin/reports/fastest-customer-revenue-growth-report',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/reports/FastestCustomerRevenueGrowthReport'
                    ),
                meta: { title: 'Fastest Customer Revenue Growth Report' },
            },
            {
                name: 'ReportRevenueByService',
                path: '/admin/reports/revenue-by-service-report',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/reports/RevenueByServiceReport'
                    ),
                meta: { title: 'Revenue By Service Report' },
            },
            {
                name: 'CustomerReportRevenueByService',
                path: '/admin/reports/customer-revenue-by-service-report',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/reports/RevenueByServicePerCustomerReport'
                    ),
                meta: { title: 'Customer Revenue By Service Report' },
            },
            {
                name: 'PartnerReportRevenueByService',
                path: '/admin/reports/partner-revenue-by-service-report',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/reports/RevenueByServicePerPartnerReport'
                    ),
                meta: { title: 'Partner Revenue By Service Report' },
            },
            {
                name: 'PaymentAnalysisReport',
                path: '/admin/reports/payment-analysis-report',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/reports/PaymentAnalysisReport'
                    ),
                meta: { title: 'Payment Analysis Report' },
            },
        ],
    },

    {
        path: '/admin/finances',
        components: {
            default: () =>
              import(
                /* webpackChunkName: "admin" */ '../pages/GenericParent'
                ),
            toolbar: () =>
              import(
                /* webpackChunkName: "common" */ '../components/core/toolbars/DefaultToolbar'
                ),
            // menu: () =>
            //   import(
            //     /* webpackChunkName: "admin" */ '../components/core/menu/CommissionMenu'
            //     ),
        },
        meta: { userTypes: ['admin'] },
        children: [
            {
                name: 'CreditCardTransactions',
                path: '/admin/finances/credit-card-Transaction',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/finances/CreditCardCharges'
                    // /* webpackChunkName: "admin" */ '../pages/reports/CreditCardCharges'
                    ),
                meta: { title: 'Credit Card Transactions',userTypes: ['admin','partner'] },
            },
            {
                // name: 'UnpaidInvoiceReport',
                name: 'InvoiceList',
                path: 'admin/finances/invoice-list',
                component: () =>
                  //import('../pages/reports/finance/UnpaidInvoices'),
                  import('../pages/admin/finances/InvoiceList'),
                meta: { title: 'Invoice List' },
            },
        ],
    },

    {
        name: 'AdminPorting',
        path: '/admin/porting',
        components: {
            default: () =>
              import(
                /* webpackChunkName: "admin" */ '../pages/GenericParent'
                ),
            toolbar: () =>
              import(
                /* webpackChunkName: "common" */ '../components/core/toolbars/DefaultToolbar'
                ),
            menu: () =>
              import(
                /* webpackChunkName: "admin" */ '../components/core/menu/AdminPortingMenu'
                ),
        },
        meta: { userTypes: ['admin'] },
        children: [
            {
                name: 'AdminPortingSummary',
                path: 'summary',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/porting/AdminPortingSummary'
                    ),
                meta: { title: 'Porting Summary' },
            },
            {
                name: 'AdminPortingList',
                path: 'carrier',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/porting/AdminPortingList'
                    ),
                meta: { title: 'Port List' },
            },
            {
                name: 'AdminPortingCalendar',
                path: 'calendar',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/porting/AdminPortingCalendar'
                    ),
                meta: { title: 'Porting Calendar' },
            },
            {
                name: 'AdminPortingDetail',
                path: 'carrier/:id',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/porting/AdminPortingDetail'
                    ),
                meta: { title: 'Port Details' },
            },

        ],
    },


    {
        name: 'AdminUserParent',
        path: '/admin/user/:userId(\\d+)',
        components: {
            default: () =>
              import(
                /* webpackChunkName: "admin" */ '../pages/GenericParent'
                ),
            toolbar: () =>
              import(
                /* webpackChunkName: "admin" */ '../components/core/toolbars/AdminToolbar'
                ),
        },
        props: {
            toolbar: { extension: 'user' }, //used to toggle the menu
        },
        meta: { userTypes: ['admin'] },
        children: [
            {
                name: 'AdminUserDetails',
                path: 'summary',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/users/UserDetails'
                    ),
                meta: { title: 'Summary' },
            },
            {
                name: 'AdminUserSessions',
                path: 'sessions',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/users/UserSessions'
                    ),
                meta: { title: 'Sessions' },
            },
            {
                name: 'AdminUserHistory',
                path: 'history',
                component: () =>
                  import(
                    /* webpackChunkName: "admin" */ '../pages/admin/users/UserHistory'
                    ),
                meta: { title: 'History' },
            },

        ]
    },



    /*

    commenting this as reference to build another admin section if needed.
    {
        name: 'AdminDevice',
        path: '/admin/devices',
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "admin" *//* '../pages/GenericParent'
                    ),
            toolbar: () =>
                import(
                    /* webpackChunkName: "admin" *//* '../components/core/toolbars/DefaultToolbar'
                    ),
            menu: () =>
                import(
                    /* webpackChunkName: "admin" *//* '../components/core/menu/AdminDeviceMenu'
                    ),
        },
        meta: {
            redirect: 'AdminDeviceList',
            userTypes: ['admin']
        },
        children: [
            {
                name: 'AdminDeviceList',
                path: 'list',
                component: () =>
                    import(
                        /* webpackChunkName: "admin" *//* '../pages/admin/devices/AdminDeviceList'
                        ),
                meta: { title: 'Device List' },
            },
        ],
    },
    */
    // {
    //     name: 'CreditCardReport',
    //     path: '/reports/credit',
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "admin" */ '../pages/reports/CreditCardCharges'
    //         ),
    //     meta: { title: 'Credit Card Transaction Report',userTypes: ['admin','partner'] },
    // },
];

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

router.beforeEach(async function (to, from, next) {

    // check if we need to retrieve without session data
    if (localStorage.token && !store.state.session.token) {
        // no session data exists, retrieve from server
        await api
          .post(
            'auth/session',
            {},
            { headers: { Authorization: `Bearer ${localStorage.token}` } }
          )
          .then(function (response) {
              // store session data
              store.commit('session', response.data);
              document.dispatchEvent(
                new CustomEvent('sessionTokenAcquired')
              );
          })
          //.catch(function (error) {
          .catch(function ( ) {
              store.commit('logout');
          });
    }
    if (to.meta.redirect) {
        next({name: to.meta.redirect, params: {...to.params}, replace: true});
    }
    if (to.matched.some((m) => m.meta.public === true)) {
        if (
          to.matched.some((m) => m.meta.publicOnly === true) &&
          store.state.session.token
        ) {
            // publicOnly = true, redirect to home
            next({ path: '/' });
        }
    } else {
        let allowed = false;
        let userTypes = [];

        if(to.meta.userTypes == undefined || to.meta.userTypes.length == 0){
            //no meta for the actual route. Traverse up the matched routes and check.
            //let matchedReversed = to.matched.reverse();
            if(to.matched.length > 0) {
                for (let i = (to.matched.length - 1); i >= 0; i--) {
                    if (to.matched[i].meta.userTypes != undefined && to.matched[i].meta.userTypes.length > 0) {
                        userTypes = to.matched[i].meta.userTypes;
                        break;
                    }
                }
            }
        }

        if(userTypes.length == 0) {
            allowed = true;
        }else{
            if(userTypes.includes(store.state.user.user_type)){
                allowed = true;
            }else{
                allowed = false;
            }
        }

        if (!store.state.session.token || !allowed){
            let login = { name: 'Login', replace: true };
            if (!to.matched.some((m) => m.meta.noRedirect === true))
                login.query = { redirect: to.path };
            next(login);
            return;
        }
    }
    next();
});

export default router;
