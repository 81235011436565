<template>
  <v-app>
    <!-- Message/notification overlay -->
    <v-snackbars :objects.sync="snackbarObjects"
                 top
                 :app="false"
                 content-class="bcsnackbar">
      <template v-slot:default="{message}">
        <v-layout align-center pr-4 v-if="message != null && message.indexOf('success:') > -1">
          <v-icon class="pr-3" dark large>mdi-check-circle-outline</v-icon>
          <v-layout column>
            {{ message.replace("success:", "") }}
          </v-layout>
        </v-layout>
        <v-layout align-center pr-4 v-else-if="message != null && message.indexOf('error:') > -1">
          <v-icon class="pr-3" dark large>mdi-alert-circle-outline</v-icon>
          <v-layout column>
            {{ message.replace("error:", "") }}
          </v-layout>
        </v-layout>
        <v-layout v-else align-center pr-4>
          {{ message}}
        </v-layout>
      </template>
      <template v-slot:action="{close}">
        <!-- use hack to hide close button with {{null}}-->
        <v-icon class="pr-3" dark @click="close( )">mdi-close</v-icon>
      </template>
    </v-snackbars>

    <!-- Navigation menu -->
    <router-view
      name="menu"
      v-model="view.secondaryMenu"
      :hide="mobileView"
      @menu="showMainMenu"
    />
    <AdminMenu
      v-if="$store.state.user.isAdmin"
      v-model="view.mainMenu"
      :hide="mobileView || $route.matched.some((m) => m.components.menu)"
    />
    <PartnerMenu
      v-if="$store.state.user.isPartner"
      v-model="view.mainMenu"
      :hide="mobileView || $route.matched.some((m) => m.components.menu)"
      main-menu
    />

    <!-- Application Bar -->
    <ApplicationBar
      v-if="$store.state.user.isLoggedIn"
      @menu="showMenu"
      :menu-button="
                mobileView || ($route.matched.some((m) => m.components.menu) && $store.state.user.isNotCustomer)
            "
    />
      <!--
      Note that the v-banners do not been to stack nicely.
      After a long while of trying to get it to function it has been decided
      to wrap them in a floating div.-->
      <v-slide-y-transition>
        <div class="sticky-container" :style="{'top':topGap+'px'}" v-if="toggle2FABanner || toggleStatusBanner || (view.applicationData.banners && view.applicationData.banners.length > 0)">
          <!-- 2fa alert banner -->
          <Banner
            icon="mdi-alert"
            message="Your account is not protected by two-factor authentication. We recommend setting it up now."
            colorTag="info"
            accept="Go to My Account"
            dismiss="Dismiss"
            :show="toggle2FABanner"
            @accept="setup2fa"
            @dismiss="view.showTwoFactorBanner = false"
          />
          <Banner
            v-if="view.statusBanner.show"
            :message="'There is a '+view.applicationData.highest_state_or_status.name+' status update. See the status page for more information.'"
            :colorTag="view.statusBanner.colourTag"
            accept="Status Page"
            dismiss="Dismiss"
            :show="toggleStatusBanner"
            @accept="toStatusPage"
            @dismiss="view.statusBanner.show = false"
          />
          <Banner
            v-for="(banner, index1) in view.applicationData.banners"
            v-bind:key="index1"
            :message="banner.message"
            :color-tag="banner.colorTag || 'info'"
            :accept="banner.acceptBtn || null"
            :dismiss="banner.dismissBtn || 'Dismiss'"
            :show="toggleBanner(index1)"
            @dismiss="view.applicationData.banners[index1].show = false"
          >
          </Banner>
        </div>
      </v-slide-y-transition>

        <SpinnerOverlay
                v-if="
                    (!$route.matched.some((m) => m.meta.public) && !$store.state.user.isLoggedIn) ||
                    view.update.inProgress || isBusy
                "
                :opacity="(!$store.state.user.isLoggedIn || view.update.inProgress) ? 1 : undefined"
                :message="view.update.inProgress ? 'Updating...' : 'Loading...'"
        />
        <!-- Content -->
        <v-main id="main" tag="main" class="fill-height">
            <!-- toolbar -->
            <v-fade-transition mode="out-in">
              <router-view name="toolbar"></router-view>
            </v-fade-transition>

            <!-- main content -->
            <v-fade-transition mode="out-in">
                <router-view key="mainView"></router-view>
            </v-fade-transition>
        </v-main>
        <!-- content to only show on non-public routes -->
        <div v-if="!$route.matched.some((m) => m.meta.public)">
            <!-- Application Errors -->
            <ApplicationError v-for="(error, index) of $store.state.errors" :key="'error-message-' + index" :error="error" :index="index" />
            <!-- Application Updates -->
            <ApplicationUpdateDialog
                v-if="$store.state.user.isLoggedIn && view.update.available"
                :visible="!view.update.dismissed && !view.update.inProgress"
                @update="updateApplication"
                @cancel="view.update.dismissed=true"
            />
            <ApplicationUpdateFooter v-if="$store.state.user.isLoggedIn && view.update.available && view.update.dismissed && !view.update.inProgress" @update="updateApplication" />
        </div>
        <!-- Footer -->
        <!-- Show a message for development. Not sure why it was created, prob warn between env. Disabled. -->
        <ApplicationFooter v-if="view.showDevelopmentFooter && false" />
    </v-app>
</template>

<script>
import ApplicationBar from './components/core/ApplicationBar';
import ApplicationFooter from './components/core/ApplicationFooter';
import Banner from './components/core/Banner';
import SpinnerOverlay from './components/pieces/Global/SpinnerOverlay';
import AdminMenu from './components/core/menu/AdminMenu';
import PartnerMenu from './components/core/menu/PartnerMenu';
import ApplicationError from './components/core/ApplicationError';
import ApplicationUpdateFooter from './components/core/update/ApplicationUpdateFooter';
import ApplicationUpdateDialog from './components/core/update/ApplicationUpdateDialog';
import VSnackbars from "v-snackbars";

export default {
  name: 'App',
  components: {
    ApplicationUpdateDialog,
    ApplicationUpdateFooter,
    ApplicationError,
    PartnerMenu,
    AdminMenu,
    SpinnerOverlay,
    Banner,
    ApplicationBar,
    ApplicationFooter,
    "v-snackbars":VSnackbars,
  },
  data: () => ({
    busy: false,
    view: {
      mainMenu: false,
      secondaryMenu: false,
      showTwoFactorBanner: true,
      showDevelopmentFooter: process.env.VUE_APP_DEBUG === 'true',
      update: {
        available: false,
        dismissed: false,
        inProgress: false
      },
      applicationData:{
        banners:[]
      },
      statusBanner:{
        show:false,
        colourTag:"info",
      }
    },
    registration: null,
    snackbarObjects:[],
    banners:[],
  }),
  methods: {
    showMenu: function () {
      if (
        this.mobileView &&
        this.$route.matched.some((m) => m.components.menu)
      ) {
        this.view.secondaryMenu = true;
      } else {
        this.view.mainMenu = true;
      }
    },
    showMainMenu: function () {
      this.view.secondaryMenu = false;
      this.view.mainMenu = true;
    },
    showBanner: function () {
      this.view.showBanner = true;
    },
    setup2fa: function () {
      this.view.showTwoFactorBanner = false;
      this.$router.push({ name: 'MyAccount' });
    },
    updateAvailable(e) {
      this.registration = e.detail;
      this.view.update.available = true;
    },
    updateApplication() {
      this.view.update.inProgress = true;
      if (this.registration || this.registration.waiting)
        this.registration.waiting.postMessage({type:'SKIP_WAITING'});
    },
    async update() {
      this.busy = true;
      /*
        We need to reset api object as it can be initiated before the request.
        This happens on login.
       */
      this.Api.setHttpObject();
      const response = await this.Api.send('get','/application-data');
      this.busy = false;
      this.view.statusBanner.show = false;
      this.view.statusBanner.colourTag = "success";
      if(response.success){
        this.$set(this.view, 'applicationData', response.data);
        if(response.data.highest_state_or_status.value > 1){
          this.view.statusBanner.show = true;
          if(response.data.highest_state_or_status.value == 2){
            this.view.statusBanner.colourTag = "info";
          }else if(response.data.highest_state_or_status.value == 3){
            this.view.statusBanner.colourTag = "warning";
          }else if(response.data.highest_state_or_status.value == 4){
            this.view.statusBanner.colourTag = "error";
          }
        }
      }else{
        this.view.applicationData = {};
      }
    },

    /**
     * This is catered for the status banner that is triggered if there are any status messages to show.
     */
    toStatusPage:function( ){
      let targetRouteName = '';

      if (this.$store.state.user.isAdmin) {
        targetRouteName = 'AdminOperationStatus';
      } else if (this.$store.state.user.isPartner) {
        targetRouteName = 'PartnerOperationalStatus';
      } else if (this.$store.state.user.isCustomer) {
        targetRouteName = 'CustomerOperationalStatus';
      } else {
        alert("Unrecognized user type.");
        return;
      }

      this.view.statusBanner.show = false;
      if (this.$router.currentRoute.name === targetRouteName) {
        // Optionally, you could also show a message or perform some other logic here
        window.location.reload();
      } else {
        this.$router.push({ name: targetRouteName });
      }
    },

    toggleBanner(index){
      return this.$store.state.user.isLoggedIn && this.view.applicationData.banners[index].show;
    },

    addBanner(event){
      const data = event.detail;
      if (!data.hasOwnProperty('tag')) {
        alert('Missing tag value.');
        return;
      }

      //check if tag is unique.
      this.view.applicationData.banners.forEach((banner)=>{
        if(banner.tag == data.tag){
          console.error('Banner with tag ('+data.tag+') already exists.');
        }
      });

      //add in new banner
      let new_banner = {};
      new_banner.tag = data.tag;
      new_banner.show = true;
      if (data.hasOwnProperty('icon')) {
        new_banner.icon = data.icon;
      }else{
        new_banner.icon = "mdi-alert";
      }

      new_banner.message = null;
      if (data.hasOwnProperty('message')) {
        new_banner.message = data.message;
      }

      new_banner.colorTag = "info";
      if (data.hasOwnProperty('colorTag')) {
        new_banner.colorTag = data.colorTag;
      }

      new_banner.accept = null;
      if (data.hasOwnProperty('accept')) {
        new_banner.accept = data.accept;
      }

      new_banner.dismiss = 'Dismiss';
      if (data.hasOwnProperty('dismiss')) {
        new_banner.dismiss = data.dismiss;
      }

      new_banner.accept_handler = null;
      if (data.hasOwnProperty('accept_handler')) {
        new_banner.accept_handler = data.accept_handler;
      }

      new_banner.show = true;
      this.view.applicationData.banners.push(new_banner);
    },

    // dismissBanner(tag){
    //   // this.banners.forEach((banner)=>{
    //   //   if(banner.tag == tag){
    //   //     banner.show = false;
    //   //   }
    //   // });
    //   this.banners = this.banners.filter(banner => banner.tag !== tag);
    // }

  },
  computed: {
    mobileView() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    snackbarObjectStore( ){
      return this.$store.state.data.snackbarObjectStore;
    },
    isBusy( ){
      return this.busy;
    },
    toggle2FABanner( ){
      return this.$store.state.user.isLoggedIn &&
        !this.$store.state.user.twofactor_enabled &&
        this.view.showTwoFactorBanner;
    },
    toggleStatusBanner( ){
      return this.$store.state.user.isLoggedIn && this.view.statusBanner.show;
    },

    topGap: function () {
      return this.$vuetify.breakpoint.mdAndUp ? 64 : 56;
    },
    bannerFiltered( ){
      return this.banners.filter(item => item.show === true);
    }
  },
  created() {
    /*
      Note this p first page is loaded before a session token is obtained.
      From login we get a login token. Then during auth validation we pull a session token with the login token.
      Hence this page will load without a session token. Hence an API call cannot be made as there is no session token.
      So we use a listen to trigger.
     */
    document.addEventListener('sessionTokenAcquired', this.update);

    // check current dark/light theme preference
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.$vuetify.theme.dark = true;
    }

    // listen for changes to dark/light theme preference
    window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (event) => {
        this.$vuetify.theme.dark = event.matches;
      });

    // listen for update event from service worker to trigger user notifications
    document.addEventListener(
      'serviceWorkerUpdateEvent', this.updateAvailable, { once: true }
    );

    // listen for update to controller and refresh
    navigator.serviceWorker.addEventListener(
      'controllerchange', () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      }
    );

    document.addEventListener('addBanner', this.addBanner);

  },
  watch: {
    $route(to, from) {
      this.view.mainMenu = false;
      this.$vuetify.goTo(0);
      if (from.meta.public && !to.meta.public && this.view.update.available)
        this.updateApplication();
    },
    mainMenu(val) {
      if (!val) this.view.secondaryMenu = true;
    },
    snackbarObjectStore(val){
      if(val != null) {
        //this.showSuccessSnackBar = true;
        this.snackbarObjects.push(val)
        this.$store.commit('data', {
          name:  'snackbarObjectStore',
          data: null,
        });
        //setTimeout(() => {this.showSuccessSnackBar = false}, 5000);
      }
    },
  },
};
</script>
<style>
.sticky-container {
  position: sticky;
  z-index: 10;
}
</style>